import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, of } from 'rxjs';
import { Address } from '../core/models/address';

import * as PRODUCTS from '../core/data/products';
import { Document } from '../core/models/document';
import { Product } from '../core/models/product';
import { Region } from '../core/models/region';
import { Contact, Owner, User, UserDB, UserType } from '../core/models/user';
import { AuthService } from '../core/services/auth.service';
import { DataService } from '../core/services/data.service';
import {
  IOrder,
  SetActeType,
  SetAskForExpertise,
  SetAskForOffer,
  SetNN,
  SetPaymentType,
  SetRemarks,
  SetSendByMail,
  ToggleOnlinePayment,
  ToggleSendByMail
} from '../core/store/order';
import * as orderSelector from '../core/store/order/order.selectors';
import { IUI, SetIAgree, SetStep4Valid } from '../core/store/ui';
import * as uiSelector from '../core/store/ui/ui.selectors';
import { AppState } from '../interfaces';

import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, take } from 'rxjs/operators';
import { orderMeansType } from '../core/data';
import {
  displayAskForExpertise,
  getPricesID,
  projectID,
  showFinalOptions,
} from '../core/data/theme-config';
import { VENTES } from '../core/enums/vente.enum';
import {
  GtmService,
  PurchaseEventItemParams,
  PurchaseEventParams
} from '../core/services/google-tag-manager.service';
import { LoggerService } from '../core/services/logger.service';
import { SendEmailService } from '../core/services/send-email.service';
import { CongratsDialogComponent } from '../dialogs/congrats-dialog/congrats-dialog.component';
import { DefaultDialogComponent } from '../dialogs/default-dialog/default-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss']
})
export class Step4Component implements OnInit {
  get isHainautOrNamurorNVN() {
    return projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege';
  }

  API_CREATE_ORDER = 'https://certinergiesystems.azurewebsites.net/api/NewOrdering/create';
  // paymentType: string;
  acteType: string;
  onlinePayment: boolean;
  remarks: string;
  paymentType: string;
  userZip: string;
  submitNotValid = false;
  visitAddress: Address;
  userType: UserType;
  gsmo: string;
  gsmu: string;
  phoneo: string;
  phoneu: string;
  step1IsValid: boolean;
  step2IsValid: boolean;
  step3IsValid: boolean;
  step4IsValid: boolean;

  products: Product[];
  hasPeb: boolean;
  hasElec: boolean;
  isUrgent: boolean;
  isInstallateur: boolean;
  invoiceTo: string;

  displayAskForExpertise = displayAskForExpertise;
  order: IOrder;
  ui: IUI;

  userTypes = UserType;

  $paymentType: Observable<string>;
  $acteType: Observable<string>;
  $sendByMail: Observable<boolean>;
  $remarks: Observable<string>;
  $products: Observable<Product[]>;
  $IAgree: Observable<boolean>;
  $askForExpertise: Observable<boolean>;
  $askForOffer: Observable<boolean>;
  $selectedRegion: Observable<Region>;
  $estateName: Observable<string>;
  $estateType: Observable<number>;
  $visitAddress: Observable<Address>;
  $dateButoir: Observable<any>;
  $goGetKeys: Observable<boolean>;
  $userType: Observable<UserType>;
  $contact: Observable<Contact>;
  $owner: Observable<Owner>;
  $user: Observable<User>;
  $document: Observable<Document>;
  $nn: Observable<string>;
  $language: Observable<string>;
  $onlinePayment: Observable<boolean>;
  $hasPeb: Observable<boolean>;
  $hasElec: Observable<boolean>;
  $isUrgent: Observable<boolean>;
  $userZip: Observable<string>;
  $isInstallateur: Observable<boolean>;
  $invoiceTo: Observable<string>;

  showFinalOptions = showFinalOptions;
  step4ShowByMail: boolean;

  isValid$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private dataService: DataService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private dialog: MatDialog,
    private gtmService: GtmService,
    private logger: LoggerService,
    private mailService: SendEmailService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.$paymentType = this.store.select(orderSelector.getPaymentType);
    this.$acteType = this.store.select(orderSelector.getActeType);
    this.$sendByMail = this.store.select(orderSelector.getSendByMail);
    this.$remarks = this.store.select(orderSelector.getRemarks);
    this.$products = this.store.select(orderSelector.getProducts);
    this.$IAgree = this.store.select(uiSelector.getIAgree);
    this.$askForExpertise = this.store.select(orderSelector.getAskForExpertise);
    this.$askForOffer = this.store.select(orderSelector.getAskForOffer);
    this.$selectedRegion = this.store.select(orderSelector.getRegionInfo);
    this.$estateName = this.store.select(orderSelector.getEstateName);
    this.$visitAddress = this.store.select(orderSelector.getAddress);
    this.$dateButoir = this.store.select(orderSelector.getDateButoir);
    this.$goGetKeys = this.store.select(orderSelector.getGoGeyKeys);
    this.$contact = this.store.select(orderSelector.getContact);
    this.$userType = this.store.select(orderSelector.getUserType);
    this.$owner = this.store.select(orderSelector.getOwner);
    this.$user = this.store.select(orderSelector.getUser);
    this.$document = this.store.select(orderSelector.getDocument);
    this.$nn = this.store.select(orderSelector.getNN);
    this.$language = this.store.select(uiSelector.getLanguage);
    this.$estateType = this.store.select(orderSelector.getEstateID);
    this.$onlinePayment = this.store.select(orderSelector.getOnlinePayment);
    this.$hasPeb = this.store.select(orderSelector.hasPeb);
    this.$hasElec = this.store.select(orderSelector.hasElec);
    this.$isUrgent = this.store.select(orderSelector.getUrgence);
    this.$userZip = this.store.select(orderSelector.getUserZip);
    this.$invoiceTo = this.store.select(orderSelector.getInvoiceTo);

    this.$products.subscribe(p => (this.products = p));
    this.$hasPeb.subscribe(h => (this.hasPeb = h));
    this.$hasElec.subscribe(h => (this.hasElec = h));
    this.$isUrgent.subscribe(u => (this.isUrgent = u));
    this.$userZip.subscribe(uz => (this.userZip = uz));
    this.$invoiceTo.subscribe(it => (this.invoiceTo = it));
    this.$userType.subscribe(ut => (this.userType = ut));
    this.$visitAddress.subscribe(ad => (this.visitAddress = ad));
    this.authService.isInstallateur.subscribe(inst => (this.isInstallateur = inst));

    this.$paymentType.subscribe(pt => (this.paymentType = pt));
    this.store.select(orderSelector.getOrder).subscribe(o => (this.order = o));
    this.store.select(uiSelector.getUI).subscribe(u => (this.ui = u));
    this.store.select(uiSelector.getStep1IsValid).subscribe(s => (this.step1IsValid = s));
    this.store.select(uiSelector.getStep2IsValid).subscribe(s => (this.step2IsValid = s));
    this.store.select(uiSelector.getStep3IsValid).subscribe(s => (this.step3IsValid = s));
    this.store.select(uiSelector.getStep4IsValid).subscribe(s => (this.step4IsValid = s));

    this.store.select(orderSelector.getRemarks).subscribe(string => (this.remarks = string));
  
    this.isValid$ = combineLatest([
      this.store.select(uiSelector.getStep1IsValid),
      this.store.select(uiSelector.getStep3IsValid),
      this.store.select(uiSelector.getStep4IsValid)
    ]).pipe(map(([step1, step3, step4]) => step1 && step3 && step4));

    this.$isInstallateur = this.authService.isInstallateur;

    this.$onlinePayment.subscribe(online => {
      this.onlinePayment = online;
      if (online) {
        this.store.dispatch(new SetPaymentType('online'));
      }
    });

    this.$IAgree.subscribe(ia => {
      this.store.dispatch(new SetStep4Valid(ia));
    });

    combineLatest([
      this.store.select(orderSelector.getOwner),
      this.store.select(orderSelector.getInvoiceTo)
    ]).subscribe(([owner, invoiceTo]) => {
      const isInvoiceToOwnerWithoutEmail = invoiceTo === 'toProprio' && !(owner.email?.trim());
      if (isInvoiceToOwnerWithoutEmail) {
        this.step4ShowByMail = true;
        this.store.dispatch(new SetSendByMail(true));
        return;
      }
      this.step4ShowByMail = false;
      this.store.dispatch(new SetSendByMail(false));
    })
  }

  selectPayment(type: string) {
    if (type === 'online') {
      this.store.dispatch(new ToggleOnlinePayment(true));
    } else {
      this.store.dispatch(new ToggleOnlinePayment(false));
      this.store.dispatch(new SetPaymentType(type));
    }
  }

  selectActe(type: string) {
    this.store.dispatch(new SetActeType(type));
  }

  toggleSendByMail() {
    if (!this.step4ShowByMail) return;

    this.store.dispatch(new ToggleSendByMail(true));
  }

  toggleAskForExpertise(bool: boolean) {
    this.store.dispatch(new SetAskForExpertise(bool));
  }

  toggleAskForOffer(bool: boolean) {
    this.store.dispatch(new SetAskForOffer(bool));
  }

  SetRemarks(rem: string) {
    this.store.dispatch(new SetRemarks(rem));
  }

  toggleIAgree() {
    this.store.dispatch(new SetIAgree(true));
  }

  setNN(nn: string) {
    this.store.dispatch(new SetNN(nn));
  }

  checkIfProducts(order): boolean {
    const hasProdducts = order.OrderProducts.length > 0;
    const hasPrice = order.Invoice.TotalPrice > 0;
    if (hasProdducts && hasPrice) {
      return true;
    }
    return false;
  }

  finalizeOrder() {
    // Trivial verification cause if !isValid button is disabled
    this.isValid$.pipe(take(1)).subscribe(orderIsValid => {
      // <-- NABIL reformat/map and clean order object before posting to Certinergie/NewOrder/api
      if (orderIsValid) {
        this.submitNotValid = false;
        const order = this.mapdOrderForDb();
        const hasProducts = this.checkIfProducts(order);

        if (!hasProducts) {
          this.dialog.open(DefaultDialogComponent, {
            panelClass: ['default-dialog'],
            data: {
              dialog: this.dialog,
              style: 'error'
            },
            disableClose: true,
            autoFocus: false
          });
        }
        try {
          this.logger.log(JSON.stringify(order));
        } catch (e) {
          this.logger.log('Error while parsing order to string');
        } finally {
          // NABIL Add link to Stripe payment with OrderID from success response

          this.http.post<any>(this.API_CREATE_ORDER, order).subscribe(
            val => {
              this.logger.log(`🚀`);
              // this.snackBar.open('🚀 Commande créée avec succès 🚀');
              const userDB: UserDB = val.Data;
              const orderID: string = val.Data.OrderID;
              // Emit eCommerce GA
              const purchaseItems: Partial<PurchaseEventItemParams>[] = order.OrderProducts.map(op => ({
                item_id: op.ProductTypeId.toString(),
                item_name: op.ProductName,
                price: parseFloat(op.TotalTVAC),
                discount: parseFloat(op.Reduction),
              }));
              const purchaseParams: PurchaseEventParams = {
                transaction_id: orderID,
                currency: 'EUR',
                affiliation: projectID,
                value: order.Invoice.TotalPrice,
                items: purchaseItems,
              };
              this.gtmService.emitPurchase(purchaseParams);

              const displayAgenda = true;

              const dialogRef = this.dialog.open(CongratsDialogComponent, {
                panelClass: ['congrats-dialog', 'no-padding-dialog'],
                data: {
                  onlinePayment: this.onlinePayment,
                  orderid: orderID,
                  dialog: this.dialog,
                  displayAgenda: displayAgenda
                },
                disableClose: true,
                autoFocus: false
              });

              dialogRef.afterClosed().subscribe(data => {
                this.authService.orderSuccessLogin(userDB.UserID, 2000, false);
              });
            },
            error => {
              this.logger.log('POST call in error', error);
              this.snackBar.open('Une erreur a eu lieu lors de la création de votre commande 😰');
            },
            () => {
              this.logger.log('The POST observable is now completed.');
            }
          );
        }
      } else {
        this.submitNotValid = true;
        this.logger.log('😰 order not valid');
        this.snackBar.open(this.translateService.instant('error-message.order-error'));
      }
    });
  }

  mapdOrderForDb() {
    try {
      const o: IOrder = JSON.parse(JSON.stringify(this.order));
      const u: IUI = JSON.parse(JSON.stringify(this.ui));
      const notaryProject =
        projectID === 'hainaut' || projectID === 'namur' || projectID === 'nvn' || projectID === 'liege';

      const packComm = this.dataService.packsDB?.find(pa => pa.id === o.packID)?.commission;
      let commOrder = packComm || 0;
      let commTotal = packComm || 0;

      const hasDescUrba = o.products.find(p => p.id === PRODUCTS.DESCRIPTIF_URBA);

      let docName = '';

      if (o.document.firstName && o.document.lastName) {
        docName = o.document.firstName + ' ' + o.document.lastName;
      } else if (o.document.firstName) {
        docName = o.document.firstName;
      } else if (o.document.lastName) {
        docName = o.document.lastName;
      }

      if (this.userType === 1688) {
        if (o.user.userid === '') {
          this.gsmu = o.user.gsm != null ? o.user.indicator + o.user.gsm.substring(1) : '';
          this.phoneu = o.user.phone != null ? o.user.indicatorphone + o.user.phone.substring(1) : '';
        } else {
          this.gsmu = o.user.gsm;
          this.phoneu = o.user.phone;
        }
        this.gsmo = this.gsmu;
        this.phoneo = this.phoneu;
      } else {
        this.gsmo = o.owner.gsm != null ? o.owner.indicator + o.owner.gsm.substring(1) : '';
        this.phoneo = o.owner.phone != null ? o.owner.indicatorphone + o.owner.phone.substring(1) : '';
      }

      var invoiceVat = o.document.tva && o.document.tva !== '' ? o.document.tva : '';
      var invoiceCompany = o.document.companyName && o.document.companyName !== '' ? o.document.companyName : '';
      if (o.invoiceTo === 'toProprio' && o.user.tva && o.user.tva != '') {
        if (this.userType === 1688) {
          invoiceVat = o.user.tva;
          invoiceCompany = o.user.companyname;
        } else {
          invoiceVat = o.owner.tva;
          invoiceCompany = o.owner.companyname;
        }
      } else if (o.invoiceTo === 'toProprioAndPro') {
        invoiceVat = '';
        docName = 'C/o ' + o.user.commercialname;
        invoiceCompany = o.owner.firstname + ' ' + o.owner.lastname;
      } else if (o.invoiceTo === 'toPro') {
        invoiceCompany = o.user.commercialname;
        invoiceVat = o.user.tva;
      }
      if (o.contactChoice === 'sameAsOwner') {
        o.contact.firstname = o.owner.firstname;
        o.contact.lastname = o.owner.lastname;
        o.contact.phone = this.phoneo;
        o.contact.gsm = this.gsmo;
      }
      let orderProducts = [...o.products]
        .filter(op => op.id !== 1001)
        .filter(op => op.id !== -2)
        .map(op => {
          if (op.id < 10000) {
            commOrder += op.commission;
          }
          commTotal += op.commission;
          const dbId = op.id === 11111 ? 1 : op.id === PRODUCTS.URGENT_PRO ? PRODUCTS.EXTRA : op.id;
          return {
            ProductTypeId: dbId,
            ProductName: op.id === PRODUCTS.DESCRIPTIF && hasDescUrba ? op.name + ' + Desc. Sommaire' : op.name,
            Description: o.estateType,
            TotalTVAC: op.price,
            Reduction: op.reduction,
            ContactName: o.goGetKeys ? '/' : o.contact.firstname,
            ContactSurname: o.goGetKeys ? '/' : o.contact.lastname,
            ContactType: o.contactChoice === 'other' ? 0 : o.contactChoice === 'keyAtAgency' ? 7 : 1,
            // tslint:disable-next-line:quotemark
            ContactPhone: o.goGetKeys ? "Clé à l'agence" : o.contact.phone,
            SmsNumber: o.goGetKeys ? '' : o.contact.gsm
          };
        });

      orderProducts =
        o.productsForAgency && o.productsForAgency.length > 0
          ? [...orderProducts].filter(pr => !o.productsForAgency.find(ap => ap.id === pr.ProductTypeId))
          : [...orderProducts];

      const orderProductsPro = [...o.productsForAgency]
        .filter(op => op.id !== 1001)
        .filter(op => op.id !== -2)
        .map(op => {
          if (op.id < 10000) {
            commOrder += op.commission;
          }
          commTotal += op.commission;
          return {
            ProductTypeId: op.id === 11111 ? 1 : op.id,
            ProductName: op.name,
            Description: o.estateType,
            TotalTVAC: op.price,
            Reduction: op.reduction,
            ContactName: o.goGetKeys ? '' : o.contact.firstname,
            ContactSurname: o.goGetKeys ? '' : o.contact.lastname,
            ContactType: o.contactChoice === 'other' ? 0 : o.contactChoice === 'keyAtAgency' ? 7 : 1,
            // tslint:disable-next-line:quotemark
            ContactPhone: o.goGetKeys ? '' : o.contact.phone,
            SmsNumber: o.goGetKeys ? '' : o.contact.gsm
          };
        });

      // no reductions if urgence
      let orderReductions = o.urgence
        ? null
        : o.reductions
            .filter(r => r.id !== 1001)
            .map(op => {
              return {
                TypeId: op.id,
                Description: op.type,
                Reduction: op.price
              };
            });

      let reducWithoutOR = 0;
      o.reductions.filter(r => r.id !== 1001).forEach(r => (reducWithoutOR = reducWithoutOR + +r.price));

      let immoRed = 0;
      o.reductions
        .filter(
          r =>
            r.id < 1000 &&
            r.id !== 1 &&
            r.id !== 11 &&
            r.id !== 4 &&
            r.id !== 8 &&
            r.id !== 6 &&
            r.id !== 9 &&
            r.id !== 15 &&
            r.id !== 16 &&
            r.id !== 51 &&
            r.id !== 55 &&
            r.id !== 56 &&
            r.id !== 57 &&
            r.id !== 17
        )
        .forEach(r => (immoRed = immoRed + +r.price));

      o.reduction = o.urgence ? 0 : reducWithoutOR;

      o.reduction = o.reduction - o.commission;

      // defaults non needed values
      if (o.paymentType !== 'acte') {
        o.nn = null;
        o.acteType = 'ILLPAY';
      }
      if (o.urgence) {
        orderReductions = null;
        o.promoCode = null;
      }

      let remarque = o.dateButoir
        ? 'Date Butoir : ' +
          (function(d) {
            const date = new Date(d);
            return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
          })(o.dateButoir)
        : '';

      /* 
      if (data.partielNumber !== '') {
        remarque =
          remarque + "\n" + "Numéro du partiel existant : " + data.partielNumber;
      } 
      */

      if (o.pebNb && o.pebNb !== '') {
        remarque = remarque + '\n' + 'Numéro du PEB partiel existant : ' + o.pebNb;
      }

      if (o.nn != null) {
        // tslint:disable-next-line:quotemark
        remarque = remarque + '\n' + "Numéro national en cas d'acte : " + o.nn;
      }

      if (o.promoCode && o.promoCode !== '') {
        remarque = remarque + '\n' + 'codepromo:' + o.promoCode;
      }

      if (o.contacts && o.contacts.length) {
        let formattedContacts = '';
        o.contacts.forEach(
          co =>
            (formattedContacts =
              formattedContacts + `${co.description} | ${co.firstname} ${co.lastname} - ${co.phone} - ${co.gsm} \n`)
        );
        remarque = remarque + '\n' + 'Contacts:' + '\n' + formattedContacts;
      }

      if (remarque !== '') {
        remarque = remarque + '\n' + '-----------------';
      }

      remarque = remarque + '\n' + o.remarks;

      const lan = u.language === 'nl' ? 1 : u.language === 'en' ? 2 : u.language === 'de' ? 3 : 0;

      const dbOrder = {
        Order: {
          EstateType: o.estateType,
          RegionLocation: o.regionType,
          OrderCommission: notaryProject ? commOrder : o.commission,
          NeedBDC: o.acteType === 'BDC' || o.user.userType === this.userTypes.Agence,
          SentByMail: !o.sendByMail,
          Language: lan,
          ImmoDiscount: immoRed,
          PromoCode: o.promoCode,
          GoGetKeys: o.goGetKeys,
          AddressKeyID: o.addressKeyID,
          PromoCodeID: o.promoCodeID,
          PackID: o.packID,
          OrderMeansType: orderMeansType
        },
        Invoice: {
          IsDeedPayment: o.paymentType === 'acte',
          TotalPrice: o.price - o.reduction,
          NotaryActeChoice: o.acteType === 'BDC' ? 1 : o.acteType === 'NN' ? 2 : 0
        },
        InvoiceConsumerType: o.invoiceTo === 'toPro' ? 0 : 1,
        AddressBien: {
          Name: null,
          Company: o.owner.companyname,
          VatNumber: o.owner.tva,
          Street: o.address.street,
          Number: o.address.number,
          BoxNumber: o.address.boxNumber,
          ApartmentNumber: o.address.apartmentNumber,
          PostalCode: isNaN(parseInt(o.address.zip, 10)) ? null : parseInt(o.address.zip, 10),
          City: o.address.city,
          Country: o.address.country ? o.address.country : 'Belgique'
        },
        AddressEnvois: {
          Name: docName,
          Company: invoiceCompany,
          Street: o.document.address.street ? o.document.address.street : '',
          Number: o.document.address.number ? o.document.address.number : '',
          BoxNumber: o.document.address.boxNumber,
          ApartmentNumber: o.document.address.apartmentNumber,
          Latitude: o.document.address.lat,
          Longitude: o.document.address.lng,
          VatNumber: invoiceVat,
          PostalCode: isNaN(parseInt(o.document.address.zip, 10)) ? null : parseInt(o.document.address.zip, 10),
          City: o.document.address.city,
          Country: o.document.address.country
        },
        OrderProducts: orderProducts.filter(op => op.ProductTypeId >= 0 && op.ProductTypeId < 10000),
        OrderReductions: orderReductions,
        OrderConsumer: {
          ConsumerType: o.user.userType,
          CommercialName: o.user.companyname,
          UserID: o.user.userid,
          User: {
            Title: o.user.title,
            Email: o.user.email,
            FirstName: o.user.firstname,
            Name: o.user.lastname,
            Language: u.language === 'nl' ? 'nl-BE' : u.language === 'de' ? 'DE' : u.language === 'en' ? 'EN' : 'fr-BE',
            PhoneNumber: this.phoneu,
            TelNumber: this.gsmu,
            Status: o.user.status,
            Commission: '0'
          },
          AddressID: o.user.address.addressid,
          Address: {
            Name: null,
            Company: o.user.companyname,
            Street: o.user.address.street,
            Number: o.user.address.number,
            BoxNumber: o.user.address.boxNumber,
            ApartmentNumber: o.user.address.apartmentNumber,
            VatNumber: o.user.tva,
            Latitude: o.user.address.lat,
            Longitude: o.user.address.lng,
            PostalCode: isNaN(parseInt(o.user.address.zip, 10)) ? null : parseInt(o.user.address.zip, 10),
            City: o.user.address.city,
            Country: o.user.address.country ? o.user.address.country : 'Belgique'
          }
        },
        Owner: {
          Email: o.owner.email,
          FirstName: o.owner.firstname,
          LastName: o.owner.lastname,
          PhoneNumber: this.phoneo,
          Title: o.owner.title,
          TelNumber: this.gsmo,
          NationalNumber: null
        },
        Comment: { Type: 1, Commentaire: remarque },
        AvailableDates: [],
        OrderProductsForAgency: orderProductsPro,
        Urgent: o.urgence || o.urgencePro,
        SentByMailAgency: false,
        OrderProductsNotary: orderProducts
          .filter(
            op =>
              (op.ProductTypeId >= PRODUCTS.PublicationNotaireBE && op.ProductTypeId <= PRODUCTS.BidditFees) ||
              (op.ProductTypeId >= PRODUCTS.VISITADVALORIS && op.ProductTypeId <= PRODUCTS.LOCKSMITH) ||
              (op.ProductTypeId >= PRODUCTS.VisitGil4S && op.ProductTypeId <= PRODUCTS.VlanEditionLocal)
          )
          .map(op => ({
            ...op,
            Status:
              (op.ProductTypeId >= PRODUCTS.PublicationVlan && op.ProductTypeId <= PRODUCTS.PublicationDeMorgen) ||
              (op.ProductTypeId >= PRODUCTS.PublicationAvenir &&
                op.ProductTypeId <= PRODUCTS.PublicationProximagArdennes)
                ? 1001
                : 1000,
            description: o.venteType === VENTES.VENTE_PUB ? 3 : o.venteType
          })),
        Publications: o.parutions.map(pub => ({
          ...pub,
          saletype: o.venteType === VENTES.VENTE_PUB ? 3 : o.venteType
        })),
        PublicationLinks: o.parutionFileLinks,
        PublicationComment: o.parutionsComment,
        Visits: o.visits,
        VenteInfo: o.venteInfo,
        VenteType: o.venteType === VENTES.VENTE_PUB ? 3 : o.venteType,
        ProjectID: getPricesID,
        TotalCommission: commTotal,
        AskForExpertise: o.askForExpertise,
        NbrOfApartments: o.nbOfAppartments
      };
      return dbOrder;
    } catch (e) {
      this.snackBar.open(this.translateService.instant('error-message.order-error'));
    }
  }

  get isEligibleUserForExpertise() {
    // bxl & wal
    //return false;
    return (
      this.userType === this.userTypes.Particulier &&
      ((Number(this.userZip) >= 1000 && Number(this.userZip) < 1500) ||
        (Number(this.visitAddress.zip) >= 1000 && Number(this.visitAddress.zip) < 1500) ||
        (Number(this.userZip) >= 4000 && Number(this.userZip) < 8000) ||
        (Number(this.visitAddress.zip) >= 4000 && Number(this.visitAddress.zip) < 8000))
    );
  }
}
