import { Injectable } from '@angular/core';
import {  Router } from '@angular/router';

interface DataLayerEvent {
  event: string;
  [index: string]: string | number;
}

type DomEventParams = { event_target: string; event_label: string; }

declare var dataLayer: Array<DataLayerEvent>

export class PurchaseEventParams {
  constructor(
    public transaction_id: string,
    public affiliation: string,
    public value: number,
    public currency: string,
    public items: Partial<PurchaseEventItemParams>[]
  ) {}
}

export class PurchaseEventItemParams {
  constructor(
    public item_id: string,
    public item_name: string,
    public list_name: string,
    public item_brand: string,
    public item_category: string,
    public item_variant: string,
    public list_position: number,
    public quantity: number,
    public price: number,
    public discount: number,
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  constructor(public router: Router) {
  }

  emit<T>(eventName: string, params: T) {
    dataLayer.push({event: eventName, ...params});
    }

  emitPurchase(eventParameters: Partial<PurchaseEventParams>) {
    this.emit('purchase', eventParameters);
  }

  emitDomEvent(eventName: string, params: DomEventParams ) {
    this.emit(eventName, {
      ...params
    });
  }

  emitClick(params: DomEventParams) {
    this.emitDomEvent('click', params);
  }
}
