export const projectID: 'default' | 'greenfish' | 'hainaut' | 'namur' | 'nvn' | 'liege' = 'default';

export const hasImmoAccessProject = false;
export const hasClientTypeChoice = true;
export const hasAccessAllPack = false;

export const displayHeaderMenu = true;
export const displayHelpSection = true;

export const step1TitleImmo = false;
export const step1SubTitle = true;
export const step2HideDateButoir = false;
export const headerShowPhone = true;
export const showBigFooter = true;
export const showFinalOptions = true;

export const displayRegionSelection = true;
export const defaultRegion: -1 | 0 | 1 | 2 = -1;

export const filterNewUserTypes = [];

export const getPricesID = -1;

export const projectIDs = {
  packRoleID: ''
};

export const forceConnection = false;

export const forceInvoiceToPro = false;
export const hasSplitInvoice = true;
export const themeColor = '#8ec640';
export const displayAskForExpertise = true;
